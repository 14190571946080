import { Model } from 'common/helpers/models/model.abstract';
import { User } from 'common/models/user.model';
import { Store } from 'common/models/store.model';
import { OrderPromotion } from 'common/models/orderPromotion.model';
import { ShippingPrice } from 'common/models/shippingPrice.model';
import { Status } from 'common/models/status.model';
import { Address } from 'common/models/address.model';
import { Suborder } from 'common/models/suborder.model';

export class Order extends Model {

    id: number;
    user_id: number;
    status_id:number;
    total_without_discount: number;
    wholesales_cart_discount:number;
    total_local_discount:number;
    total:number;
    created_at: any;
    status: any;
    address: Address;
    shipping:number;
    is_order: boolean = true;
    payment_extra: number;
    total_payment: number;
    payment_method: string;
    weight: number;
    conekta_order:any;
    verified_date_limit:any;
    no_shipping: boolean = true;
    suborders: Suborder[];
    products_count:number =0;
     is_empty: boolean = false;


    protected static includes: any = {
        // "addressDelivery": AddressDelivery,
        // "addressBill": AddressBill,
        // "payment": null,
        // "orderProducts": null,
        // "orderProducts.product.photo": null,
        // "orderPromotion": OrderPromotion,
        // "shippingPrice": ShippingPrice,
        // "userPayment": null,
        // "status": Status,
        // "orderPayment": null
    }


     /**
     * Constructor
     *
     * @param publication
     */
    public constructor(order?: any) {

        super();
        order = order || {};
        this.id = order.id || null;
        this.user_id = order.user_id || null;
        this.status_id = order.status_id || null;
        this.total_without_discount = order.total_without_discount || 0;
        this.total_local_discount = order.total_local_discount || 0;
        this.wholesales_cart_discount = order.wholesales_cart_discount || 0;
        this.total = order.total || null;
        this.payment_extra = order.payment_extra || null;
        this.total_payment = order.total_payment || null;
        this.created_at = order.created_at || null;
        this.status = order.status || null;
        this.shipping = order.shipping || 0;
        this.is_order = true;
        this.is_empty = false; 
        this.weight = order.weight || null;
        this.payment_method = order.payment_method || null;
        this.address = order.address ||  new Address();
        this.conekta_order = order.conekta_order || null;
        this.no_shipping = order.no_shipping || true;
        this.verified_date_limit = order.verified_date_limit || null;
        this.suborders = order.suborders ||  [];

        for (var i =  this.suborders.length - 1; i >= 0; i--) {
            console.log('COUNT PRODUCT');
            this.products_count += this.suborders[i].products.length;
        }
    }

    public static getIncludes(...args: any): string {
        return Order.transformIncludes(Order.includes, ...args);
    }

    public static classIncludes(): any {
        return Order.includes;
    }

    public static PAYMENT_TYPES = {
        "CARD": {
            code: "card"
        },
        "SPEI": {
            code: "spei"
        },
        "OXXO": {
            code: "oxxo"
        },
        "EXTERN": {
            code: "extern"
        }
    };
}
