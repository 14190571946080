import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';

@Injectable({ providedIn: 'root' })
export class OrderPromotionResource extends Resource{
    protected availableUrlParams: Array<string> = [
        'code'
    ];
    protected urlByCode: string = "/:code/by-code";

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    public constructor(protected http: HttpClient) {
        super("/order-promotions");
    }
    
    /**
     * Get orderPromotion
     *
     * @returns {any}
     */
    public GetByCode(code: string, _success?: any, _error?: any): any {
        return this.Get(this.url(this.urlByCode), {code: code}, _success, _error);
        // return new Promise((resolve, reject) => {
        //     this._httpClient.get(this.baseUrl+'/:code/by-code')
        //         .subscribe((response: any) => {
        //             console.log("success en la request de getOrderPromotions", response);
        //             resolve(response);
        //         }, error  => {
        //             console.log("Error", error);
        //             reject(error);
        //         });
        // });
    }
}
