import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'common/services';
import { appConfig } from 'common/app-config';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SplashScreenService } from 'common/services/splash-screen.service';
import { AuthenticationResource } from 'common/resources'
import { ModalLogin } from 'common/ui/modals/login/login';

@Component({
    selector: 'modal-forgotPassword',
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['forgot-password.component.scss'],
})

export class ModalForgotPassword {
    ForgotForm: FormGroup;
    appConfig: any;
    loading: boolean;
    server_error: string = '';
    showSuccessView: boolean = false;
    public constructor(
        private _formBuilder: FormBuilder,
        public _dialogRef: MatDialogRef<ModalLogin>,
        private authService: AuthenticationService,
        private authResource: AuthenticationResource,
        public dialog: MatDialog,
        private splashScreenService: SplashScreenService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.appConfig = appConfig;
    }

    /**
 * On init
 */
    ngOnInit(): void {
        this.ForgotForm = this.createForgotForm();
    }

    /**
     * Create login form
     *
     * @returns {FormGroup}
    */
    private createForgotForm(): FormGroup {
        return this._formBuilder.group({
            email: ['', [Validators.email]],
        });
    }

    public closeModal(data?: any){
        this._dialogRef.close(data);
    }

    private successForgotPassword(response: any): void {
        console.log("success onClickForgotPassword", response);
        this.splashScreenService.hide();
        this.showSuccessView = true;
    }

    private errorForgotPassword(error: any): void {
        console.log("error onClickForgotPassword", error);
        this.splashScreenService.hide();
        this.server_error = error;
    }

    public onClickForgotPassword(formData: any) {
        console.log("this.form", this.ForgotForm);
        this.splashScreenService.show(this.splashScreenService.TYPE_SHOW_BACKGROUND);
        this.showSuccessView = false;
        this.authResource.ForgotPassword(this.ForgotForm.getRawValue(), (response)=>{
            this.successForgotPassword(response);
        }, (error) => {
            this.errorForgotPassword(error);
            
        });
    }

    openModalLogin(): void {
        this.closeModal({goToLogin: true});
    }
}
