import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Auth, User } from 'common/models';
import { AuthenticationResource } from 'common/resources';
import { UserService } from 'common/services/user.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	public currentSession: BehaviorSubject<any>;
    public auth: Auth;

	public constructor(private authResource: AuthenticationResource, private userService: UserService){
		this.currentSession = new BehaviorSubject<Auth>(JSON.parse(localStorage.getItem('currentAuth')));
        this.auth = new Auth(this.currentSession.value)
        userService.init();
	}

	public isLogged(): boolean {
		return this.auth.token != null;
	}

	public signin(data: any, _success?: any, _error?: any): any {
        return this.authResource.Signin(data,
            (response: any) => {
                console.log("estamos en el signin", response);
                console.log("preparando el login", data);
                this.login(data.email, data.password, (responseLogin:any) => {
                    console.log(responseLogin);
                    // return new Promise((resolve, reject) => {
                    // this.authService.authCheck((response: any) => {
                    //         // this.cartService.initCart();
                    //         this.successLogin();
                    //         resolve(response);
                    //     }, (error: any) => {
                    //         console.log("error en el authCheck: ", error);
                    //         // this.cartService.initCart();
                    //         resolve(true);
                    //     });
                    // });
                    // this.successLogin();
                    return true;
                }, (error: any) => {
                    return false;
                    // this.errorLogin(error);
                });
                        // this.saveSession(response.data);
                // this.userService.saveUser(response.data.user);
                // if(_success){
                //     _success(response);
                // }
                // return response.data;
            }, (error: any) => {
                 console.log("estamos en el error signin", error);
                if(_error){
                    _error(error);
                }
                this.cleanSession();
            });
	}

    public saveSession(data: any): void {
        console.log(data);
        this.auth = new Auth(data);
        this.currentSession.next(this.auth);
        localStorage.setItem('currentAuth', JSON.stringify(this.auth));
        console.log(this.auth);
    }

    public cleanSession(): void {
        localStorage.removeItem('currentAuth');
        this.auth = new Auth();
        this.currentSession.next(null);
    }

	public authCheck(_success: any, _error: any): any {
		return this.authResource.Auth(User.getIncludes(), (response: any) => {
            this.successAuthCheck(_success, response);
        }, (error: any) =>{
            this.errorAuthCheck(_error, error);
        });
	}

	public successAuthCheck(_success: any, response: any): void {
        this.userService.saveUser(response.data);
        if(_success){
            _success(response);
        }
    }

    public errorAuthCheck(_error: any, error: any): void {
        this.saveSession(new Auth());
        this.userService.saveUser(new User());
        if(_error){
            _error(error);
        }
    }

	// public confirmEmail(data: any): any {
	// 	return this.authResource.ConfirmEmail(data);
	// }

	public forgotPassword(data: any): any {
		return this.authResource.ForgotPassword(data);
	}

	public login(email: string, password: string, _success?: any, _error?: any): any {
        return this.authResource.Login({email:email, password:password}, Auth.getIncludes(true),
            (response: any) => {
                  console.log(response);

                var dataAuth={
                    token: response.access_token
                }

                this.saveSession(new Auth(dataAuth))
                // this.authCheck(response.data.user);
                if(_success){
                    _success(response);
                }
                return response;
			},
			(error: any) => {
			console.log("error from login to BE", error);
				this.cleanSession();
                if(_error){
                    _error(error);
                }
				throw error;
			});
	}

	public logout(): any {
		// remove user from local storage to log user out
        this.cleanSession();
        this.userService.saveUser(new User());
		return this.authResource.Logout(
			(response: any) => {
				return response;
			},
			(error: any) => {
				console.log("error from logout to BE", error);
				throw error;
			});
	}
}
