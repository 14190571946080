import { Model } from 'common/helpers/models/model.abstract';
import { Photo } from 'common/models/photo.model';
import { Product } from './product.model';

export class Category extends Model {
    // id: string;
    // name: string;
    // description: string;
    // isFeatured: boolean;
    // photo_id: number;
    // special_photo_id: number;
    // backgrround_photo_id: number;
    // photo: Photo;
    // specialPhoto: Photo;
    // backgroundPhoto: Photo;
    // label: string;
    // subcategories: Category[];
    // breadcrumb: any;

    id: string;
    local_id: number;
    photo: string;
    name: string;
    products: any;

    protected static includes: any = {
        'photo': Photo,
        // 'specialPhoto': Photo,
        // 'backgroundPhoto': Photo,
        // 'subcategories.photo': Category,
        // 'breadcrumb': null,
        // 'products.brand': Product,
        // 'products.photo': Photo,
        // 'products.productDiscount': null,
    };

    /**
     * Constructor
     *
     * @param category
     */
    public constructor(category?: any) {
        super(); 
        category = category || {};
        this.id = category.id || null;
        this.local_id = category.local_id || null;
        this.photo = category.photo || null;
        this.name = category.name || null;
        this.products = category.products || [];
        // category = category || {};
        // this.id = category.id || null;
        // this.name = category.name || '';
        // this.description = category.description || '';
        // this.label = category.label || null;
        // this.photo = category.photo || null;
        // this.specialPhoto = category.specialPhoto || null;
        // this.backgroundPhoto = category.backgroundPhoto || null;
        // this.subcategories = category.subcategories || [];
        // this.breadcrumb = category.breadcrumb || null;
    }

    public static getIncludes(...args: any): string {
        return Category.transformIncludes(Category.includes, ...args);
    }

    public static classIncludes(): any {
        return Category.includes;
    }
}
