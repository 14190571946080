import { Model } from 'common/helpers/models/model.abstract';

export class Wholesale extends Model {
    id: number;
    product_id: number;
    store_id: number;
    price: number;
    limit: number;
    label: string;
    type: string;
    description: string;
    active: number;

    /**
     * Constructor
     *
     * @param wholesale
     */
    public constructor(wholesale?: any) {
        super();
        wholesale = wholesale || {};
        this.id = wholesale.id || null;
        this.product_id = wholesale.product_id || '';
        this.store_id = wholesale.store_id || '';
        this.price = wholesale.price || '';
        this.limit = wholesale.limit || '';
        this.label = wholesale.label || '';
        this.type = wholesale.type || '';
        this.description = wholesale.description || '';
        this.active = wholesale.active || '';
    }
}
