import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartResource } from 'common/resources';
import { ProductResource } from 'common/resources';
import { StoreService } from 'common/services/store.service';
import { AppService } from 'common/services';
import { Cart, SubCart, Store, Product } from 'common/models';
import { AuthenticationService } from 'common/services/authentication.service';
// import {CurrencyPipe} from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CartService {
    private LOCAL_STORAGE_CART: string = "currentCart";
    private cart: Cart;
    private storeData: Store;


    private storeCart: any;
    private emptyLocalcart: any;


    public currentCart: Observable<Cart>;
    public currentCartSubject: BehaviorSubject<Cart>;



    public constructor(
        private authenticationService: AuthenticationService,
        private cartResource: CartResource,
        private storeService: StoreService,
        private productResource: ProductResource
    ) {
        this.cart = new Cart();
        this.currentCartSubject = new BehaviorSubject(this.cart);
        this.currentCart = this.currentCartSubject.asObservable();


        this.storeService.currentStore.subscribe((response: any) => {
            this.storeData = new Store(response);
            this.initCart();
        });
    }

    initCart(): void {
        console.log('INIT CART');
        this.cart = new Cart(this.getFromLocalStorage());
        this.currentCartSubject.next(this.cart);
        console.log(this.cart)
        // this.updateCart();
    }

    protected getFromLocalStorage(): any {
        return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_CART));
    }

    protected getStoreFromLocalStorage(): any {
        return JSON.parse(localStorage.getItem('oi-store')); 
    }


    createStoreCart()
    {

    }

    createEmptyLocalCart(local_id)
    {
        console.log('Create EMPTY LOCAL CART');
        var empty_local_cart = new SubCart({
            is_empty: true,
            local_id: local_id,
            total_without_discount: 0,
            wholesales_cart_discount: 0,
            local_discount:0,
            total:0,
            products: []
        });
        // var newStoreCart = [empty_local_cart];
        this.saveOnStorageLocal(empty_local_cart);
        return empty_local_cart;
    }

    resetCart(){
        return new Promise((resolve, reject) => {
            // var empty_local_cart = new SubCartModel({
            //     is_empty: true,
            //     local_id: local_id,
            //     total_without_discount: 0,
            //     wholesales_cart_discount: 0,
            //     local_discount:0,
            //     total:0,
            //     products: []
            // });

            var empty_cart = new Cart({
                is_empty: true,
                total_without_discount: 0,
                total_local_discount: 0,
                wholesales_cart_discount: 0,
                local_discount:0,
                total:0,
                shipping:0,
                weight:0,
                suborders: []
            });

            this.calculateCart(empty_cart).then((empty_cart:any) =>{
                localStorage.setItem(this.LOCAL_STORAGE_CART, JSON.stringify(empty_cart));
                this.cart =  new Cart(empty_cart);
                this.currentCartSubject.next(this.cart);
                resolve(empty_cart);
            });
        });
    }


    // getStoreCart()
    // {
    //     localStorage.getItem('oi-store-cart').then((storeCart) => {
    //         if (storeCart) {
    //             return storeCart;
    //         }
    //         else
    //         {
    //             return [];
    //         }
    //     });
    // }


    getLocalCart(local_id):any
    {

        return new Promise((resolve, reject) => {
            let storeCart = this.getFromLocalStorage();
            var localCartExist = false;
            console.log(storeCart);
            if (storeCart) {
                console.log(storeCart);
                console.log(local_id);
                for (var i = storeCart.suborders.length - 1; i >= 0; i--) {
                    console.log(storeCart.suborders[i].local_id);
                    console.log(local_id);
                    if (storeCart.suborders[i].local_id == local_id) {
                        console.log("LOCAL CART EXIST");
                        localCartExist = true;
                        var localCart = new SubCart(storeCart.suborders[i]);
                        resolve(localCart);
                    }
                }
            }

            if (!localCartExist) {
                console.log('LocalCart NOT EXIST');
                var empty_local_cart = this.createEmptyLocalCart(local_id);
                resolve(empty_local_cart);
            }
        });
    }

    getStoreCart():any
    {

        return new Promise((resolve, reject) => {
            let storeCart = this.getFromLocalStorage();
            console.log(storeCart);
            if (storeCart) {
                resolve(storeCart);
            }
            else{
                console.log('LocalCart NOT EXIST');
                var empty_store_cart = new Cart();
                resolve(empty_store_cart);
            }
        });
    }



    verifyLocalCart(local_id)
    {

    }

    verifyStoreCart(local_id)
    {

    }


    modifyQtyProductLocalCart(product)
    {
        return new Promise((resolve, reject) => {
            //console.log(product);
            // Recup cart
            this.getLocalCart(product.local_id).then((localCart) =>{
                //console.log(localCart);
                //check if product is on the localcart
                if (this.checkProductExist(localCart, product)) {
                    console.log('PRODUCT IS IN CART');
                    localCart = this.replaceQtyProductLocalCart(localCart, product);
                        this.saveOnStorageLocal(localCart).then((storeCart) =>{
                        resolve(storeCart);
                    });
                }
            });
        });
    }

    addProductLocalCart(product)
    {
        //console.log(product);
        // Recup cart
        this.getLocalCart(product.local_id).then((localCart) =>{
            //console.log(localCart);
            //check if product is on the localcart
            if (this.checkProductExist(localCart, product)) {
                console.log('PRODUCT IS IN CART');
                localCart = this.addQtyProductLocalCart(localCart, product);
                this.saveOnStorageLocal(localCart);
            }
            else
            {
                console.log('PRODUCT IS NOOOOOOOOOOOT IN CART');
                localCart.products.push(product);
                this.saveOnStorageLocal(localCart);
            }
        });
    }

    checkProductExist(localCart, product)
    {
        console.log('CHECK IF IN CART');
        console.log(localCart);
        console.log(product);
        console.log( localCart.products.length);
        for (var i = localCart.products.length - 1; i >= 0; i--) {
            console.log('IN THE FOR');
            console.log(localCart.products[i].id);
            console.log(product.id);
            if (localCart.products[i].id == product.id) {
                return true;
            }
        }
        return false;
    }


    deleteProductLocalCart(product)
    {
        return new Promise((resolve, reject) => {
            console.log(product);
            // Recup cart
            this.getLocalCart(product.local_id).then((localCart) =>{
                //console.log(localCart);
                //check if product is on the localcart
                if (this.checkProductExist(localCart, product)) {
                    localCart = this._deleteProductLocalCart(localCart, product);
                    this.calculateLocalCart(localCart).then((localCart) =>{
                        this.saveOnStorageLocal(localCart).then((storeCart) =>{
                            resolve(storeCart);
                        });
                    });
                }
            });
        });
    }

    _deleteProductLocalCart(localCart, product)
    {
        for (var i = localCart.products.length - 1; i >= 0; i--) {
            if (localCart.products[i].id == product.id) {
                localCart.products.splice(i, 1);
                return localCart;
            }
        }
    }

    deleteLocalCart(localCart)
    {
        return new Promise((resolve, reject) => {
            let storeCart = this.getFromLocalStorage();
            if (storeCart) {
                for (var i = storeCart.length - 1; i >= 0; i--) {
                    if (storeCart[i].local_id == localCart.local_id) {
                        storeCart.splice(i, 1);
                        break;
                    }
                }
                this.cart =  new Cart(storeCart);
                this.currentCartSubject.next(this.cart);
                localStorage.setItem(this.LOCAL_STORAGE_CART, JSON.stringify(storeCart));
            }
        });
    }

    addQtyProductLocalCart(localCart, product)
    {
        for (var i = localCart.products.length - 1; i >= 0; i--) {
            if (localCart.products[i].id == product.id) {
                product.quantity += localCart.products[i].quantity;
                localCart.products[i] = product;
                return localCart;
            }
        }
    }

    replaceQtyProductLocalCart(localCart, product)
    {
        for (var i = localCart.products.length - 1; i >= 0; i--) {
            if (localCart.products[i].id == product.id) {
                localCart.products[i].quantity = product.quantity;
                return localCart;
            }
        }
    }

    saveOnStorageLocal(localCart)
    {
        console.log('saveOnStorageLocal');
        console.log(localCart);
        return new Promise((resolve, reject) => {
            this.calculateLocalCart(localCart).then((localCart:any) =>{
                var hasLocalCart = false;
                var localCart = localCart;
                let storeCart = this.getFromLocalStorage();
                console.log('STORE CART');
                console.log(storeCart);
                if (storeCart) {
                    for (var i = storeCart.suborders.length - 1; i >= 0; i--) {
                        if (storeCart.suborders[i].local_id == localCart.local_id) {
                            storeCart.suborders[i] = localCart;
                            hasLocalCart = true;
                            break;
                        }
                    } 

                    if (!hasLocalCart) {
                        storeCart.suborders.push(localCart);
                    }

                    this.calculateCart(storeCart).then((storeCart:any) =>{
                        console.log('SAVE ON LOVACL');
                        console.log(storeCart);
                        this.cart =  new Cart(storeCart);
                        this.currentCartSubject.next(this.cart);
                        console.log(this.cart);
                        localStorage.setItem(this.LOCAL_STORAGE_CART, JSON.stringify(storeCart));
                        resolve(storeCart);
                    });
                }
                else{
                    // create CART
                    var empty_cart = new Cart({
                        is_empty: true,
                        total_without_discount: 0,
                        total_local_discount: 0,
                        wholesales_cart_discount: 0,
                        local_discount:0,
                        shipping:0,
                        total:0,
                        weight:0,
                        suborders: [localCart]
                    });

                    this.calculateCart(empty_cart).then((empty_cart:any) =>{
                        this.cart =  new Cart(empty_cart);
                        this.currentCartSubject.next(this.cart);
                        localStorage.setItem(this.LOCAL_STORAGE_CART, JSON.stringify(empty_cart));
                        resolve(empty_cart);
                    });
                }
            });
        });
    }



    // saveOnStorageLocal(localCart)
    // {
    //     console.log('saveOnStorageLocal');
    //     console.log(localCart);
    //     // localCart = this.calculateLocalCart(localCart);
    //     this.calculateLocalCart(localCart).then((localCart:any) =>{
    //         var hasLocalCart = false;
    //         var localCart = localCart;
    //         localStorage.getItem('oi-store-cart').then((storeCart) => {
    //             console.log('STORE CART');
    //             console.log(storeCart);
    //             if (storeCart) {
    //                 for (var i = storeCart.suborders.length - 1; i >= 0; i--) {
    //                     if (storeCart.suborders[i].local_id == localCart.local_id) {
    //                         storeCart.suborders[i] = localCart;
    //                         hasLocalCart = true;
    //                         break;
    //                     }
    //                 }

    //                 if (!hasLocalCart) {
    //                     storeCart.suborders.push(localCart);
    //                 }

    //                 this.calculateCart(storeCart).then((storeCart:any) =>{
    //                     console.log('SAVE ON LOVACL');
    //                     console.log(storeCart);
    //                     this.storage.set('oi-store-cart', storeCart);
    //                 });
    //             }
    //             else{
    //                 // create CART
    //                 var empty_cart = new CartModel({
    //                     is_empty: true,
    //                     total_without_discount: 0,
    //                     wholesales_cart_discount: 0,
    //                     local_discount:0,
    //                     total:0,
    //                     suborders: [localCart]
    //                 });

    //                 this.calculateCart(empty_cart).then((empty_cart:any) =>{
    //                     this.storage.set('oi-store-cart', empty_cart);
    //                 });
    //             }
    //             return localCart;
    //         });
    //     });
    // }

    calculateLocalCart(localCart){
        console.log('calculateLocalCart');
        console.log(localCart);
        return new Promise((resolve, reject) => {
            //console.log(localCart);
            var wholesales_cart_discount = 0;
            var total_without_discount = 0;
            var total_local_discount = 0;
            var weight = 0;
            // var shipping = 0;
            var total= 0;

            let store = this.getStoreFromLocalStorage();
            if (store) {
                console.log(store);
                for (var i = store.locales.length - 1; i >= 0; i--) {
                    if (store.locales[i].id == localCart.local_id) {
                        console.log('HEEEEEEEERE');
                        console.log(store.locales[i]);
                        localCart.discount = store.locales[i].discount;
                        console.log(localCart);
                        break;
                    }
                }
            }
            else{
                console.log('ERROR ! NO STORE MANDAR A LA VERGA');
            }
    

            for (var i = localCart.products.length - 1; i >= 0; i--) {
                localCart.products[i] = this.checkProductPrice(localCart.products[i]);
                total_without_discount += localCart.products[i].base_price * localCart.products[i].quantity;
                total += localCart.products[i].price * localCart.products[i].quantity;
                weight += localCart.products[i].weight * localCart.products[i].quantity;
                wholesales_cart_discount = total_without_discount - total;
            }

            for (let i = 0 ; i< localCart.discount.length ; i++){
                if (total >= localCart.discount[i].limit_low && localCart.discount[i].discount > 0) {
                    total_local_discount = total * (localCart.discount[i].discount/100);
                    console.log('DISCOUNT LOCAL');
                    console.log(total_local_discount);
                }
            }

            total = total - total_local_discount;
            localCart.total_without_discount = total_without_discount;
            localCart.wholesales_cart_discount = wholesales_cart_discount;
            localCart.total_local_discount = total_local_discount;
            localCart.weight = weight;
            localCart.total = total;
            resolve(localCart);
        });
    }

    calculateCart(cart){
        console.log('calculateCart');
        console.log(cart);
        return new Promise((resolve, reject) => {
            //console.log(localCart);

            // LE RESET
            cart.wholesales_cart_discount = 0;
            cart.total_without_discount = 0;
            cart.total_local_discount = 0;
            cart.weight = 0;
            cart.shipping = 0;
            cart.total= 0;

            console.log(cart);


            // RECALCUL
            for (var i = cart.suborders.length - 1; i >= 0; i--) {
                console.log('RECALCUL');
                console.log(cart.suborders[i].weight)
                cart.wholesales_cart_discount += cart.suborders[i].wholesales_cart_discount;
                cart.total_local_discount +=  cart.suborders[i].total_local_discount;
                cart.total_without_discount += cart.suborders[i].total_without_discount;
                cart.weight += cart.suborders[i].weight;
                cart.total += cart.suborders[i].total;
                console.log(cart.weight);
            }


            let store = this.getStoreFromLocalStorage();
            console.log('CHECK NO SHIPPING');
            if (cart.no_shipping) {
                cart.shipping = 0;
                console.log('NO SHIPPING');
            }
            else
            {
                console.log('SHIPPING');
                for (let i = 0 ; i< store.shippingprice.length ; i++){
                    console.log(store.shippingprice[i].limit_low);
                    console.log(cart.weight);
                    if (cart.weight >= store.shippingprice[i].limit_low){
                        cart.shipping = store.shippingprice[i].price;
                        console.log(cart.shipping);
                    }
                }
            }
            cart.total  += cart.shipping;
            console.log(cart);
            resolve(cart);
            
        });
    }


    checkProductPrice(product){
        product.price = product.base_price;
        for (let i = 0 ; i<product.wholesales.length ; i++){
            if (product.quantity >= product.wholesales[i].limit_low){
                product.price = product.wholesales[i].price;
            }
        }

        if (product.discount) {
            product.price = product.price - (product.price * product.discount/100);
        }
        return product;
    }

    // ShippingCalculate(order){
    //     return new Promise((resolve, reject) => {
    //         localStorage.getItem('oi-store').then((store) => {
    //             console.log('SHIPPING');
    //             for (let i = 0 ; i< store.shippingprice.length ; i++){
    //                 console.log(store.shippingprice[i].limit_low);
    //                 console.log(order.weight);
    //                 if (order.weight >= store.shippingprice[i].limit_low){
    //                     order.shipping = store.shippingprice[i].price;
    //                     console.log(order.shipping);
    //                 }
    //             }
    //             console.log(order);
    //             order = this.calculateCart(order);
    //             resolve(order);
    //         });
    //     });
    // }

    // noShippingCalculate(order){

    //     return new Promise((resolve, reject) => {
    //         order.shipping = 0;
    //         order = this.calculateCart(order);
    //         resolve(order);
    //     });
    // }

}
