import { Model } from 'common/helpers/models/model.abstract';
import { SubCart } from 'common/models/subcart.model';

export class Cart extends Model {

    total_without_discount: number;
    wholesales_cart_discount:number; // wholesale discount en pesos (discount sum wholesale)
    total_local_discount:number;  // global discount en pesos (discount supp limit)
    total:number;
    suborders: any;
    address: any;
    shipping:number = 0;
    is_order: boolean = false;
    is_empty: boolean = false;
    weight:number = 0; // for shipping calculate
    products_count:number =0;
    no_shipping: boolean = true;

    protected static includes: any = {
        // "photo": null,
        // "productDiscount": null
    };

    /**
     * Constructor
     *
     * @param publication
    */

    public constructor(cart?: any) {
        super();
        cart = cart || {};
        // this.local_id = cart.local_id || null;
        this.total_without_discount = cart.total_without_discount || null;
        this.total_local_discount = cart.total_local_discount || 0;
        this.wholesales_cart_discount = cart.wholesales_cart_discount || 0;
        // this.local_discount_limit = cart.local_discount_limit || null;
        // this.local_discount = cart.local_discount || null;
        this.shipping = cart.shipping || 0;
        this.total = cart.total || null;
        this.weight = cart.weight || null;
        this.address = cart.address || null;
        this.is_order = cart.is_order || false;
        this.is_empty = cart.is_empty || false;
        this.no_shipping =   cart.no_shipping || true;
        this.products_count = cart.products_count || 0;
        this.suborders = cart.suborders ||
        [
            new SubCart()
        ];

        for (var i =  this.suborders.length - 1; i >= 0; i--) {
            console.log('COUNT PRODUCT');
            this.products_count += this.suborders[i].products.length;
        }
    }

    public static getIncludes(...args: any): string {
        return Cart.transformIncludes(Cart.includes, ...args);
    }

    public static classIncludes(): any {
        return Cart.includes;
    }
}
