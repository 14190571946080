import { Model } from 'common/helpers/models/model.abstract';
import { Permission} from 'common/models/permission.model';
import { Role} from 'common/models/role.model';
import { Order} from 'common/models/order.model';
import { Address} from 'common/models/address.model';

export class User extends Model {
    id: number;
    name: string;
    email: any;
    conektaCustomer:any;
    orders: Order[];
    addresses: Address[];
    ordersverified: any = [];

    protected static includes: any = {
        // 'cart': null,
        // 'store': null,
        // 'addressDeliveries': null,
        // 'addressBills': null,
        // 'lastAddressDelivery': null,
        // 'lastAddressBill': null,
        // 'waitingOrders': null
    }

    public constructor(user?: any){
        super();
        user = user || {};
        this.id = user.id || null;
        this.email = user.email || null;
        this.name = user.name || null;
        this.conektaCustomer = user.conekta_customer || null;
        this.ordersverified = user.ordersverified || [];
        this.orders = user.orders || [];
        this.addresses = user.addresses || [];

    }

    public static getIncludes(...args: any): string {
        return User.transformIncludes(User.includes, ...args);
    }

    public static classIncludes(): any {
        return User.includes;
    }
}
