import { Model } from 'common/helpers/models/model.abstract';

export class Permission extends Model {
    public id: number;
    public code: string;
    public label: string;

    /**
     * Constructor
     *
     * @param PromotionType
     */
    public constructor(permission?: any) {
        super();
        permission = permission || {};
        this.id = permission.id || null;
        this.code = permission.code || null;
        this.label = permission.label || null;
    }
}
