import { Component,OnInit,OnDestroy  } from '@angular/core';
import { StoreService } from 'common/services';
import { Category, Store } from 'common/models';
import { AuthenticationService, UserService } from 'common/services';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ModalLogin } from 'common/ui/modals/login/login';
import { TranslationLoaderService } from 'common/services/translation-loader.service';
import { locale as spanish } from './i18n/es';
import { Subject } from 'rxjs';

@Component({
    selector   : 'e-commerce-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls  : ['./mobile-menu.component.scss']
})
export class MobileMenuComponent
{
	// categories : Category[];
	store : Store;
	isLogged : Boolean;
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 */
	constructor(
		private storeService : StoreService,
		public authService : AuthenticationService,
        public userService : UserService,
		public dialog: MatDialog,
		private translationLoaderService: TranslationLoaderService,
	){
		// this.categories = [new Category()];
		this._unsubscribeAll = new Subject();
		this.translationLoaderService.loadTranslations(spanish);
	}

	openModalLogin(): void {
		document.querySelector('body').classList.remove('mmenu-active');
        const dialogRef = this.dialog.open(ModalLogin);

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

	ngOnInit(){
		this.storeService.currentStore.subscribe(response => {
			this.store = <Store>(response);
			// this.categories = <Category[]>(response.categories);
		});

		this.authService.currentSession.subscribe(response => {
            this.isLogged = this.authService.isLogged();
        });
	}

	ngOnDestroy(){
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
