import { Model } from 'common/helpers/models/model.abstract';
import { Product } from 'common/models/product.model';

export class SubCart extends Model {

    local_id: number;
    total_without_discount: number;
    wholesales_cart_discount:number; // wholesale discount en pesos (discount sum wholesale)
    total_local_discount:number;  // global discount en pesos (discount supp limit)
    local_discount_limit:number; // limit discount en pesos (limit)
    local_discount:number;  // percent global to apply
    total:number;
    products: Product[];
    is_order: boolean = false;
    is_empty: boolean = false;
    weight:number = 0; // for shipping calculate


    protected static includes: any = {
        // "photo": null,
        // "productDiscount": null
    };

   
    /**
     * Constructor
     *
     * @param publication
    */

    public constructor(subcart?: any) {
        super();
        subcart = subcart || {};
        this.local_id = subcart.local_id || null;
        this.total_without_discount = subcart.total_without_discount || null;
        this.total_local_discount = subcart.total_local_discount || null;
        this.wholesales_cart_discount = subcart.wholesales_cart_discount || null;
        this.local_discount_limit = subcart.local_discount_limit || null;
        this.local_discount = subcart.local_discount || null;
        this.total = subcart.total || null;
        this.is_order = subcart.is_order || false;
        this.is_empty = subcart.is_empty || false;
        this.weight = subcart.weight || 0;
        this.products = subcart.products || [];
    }


    public static getIncludes(...args: any): string {
        return SubCart.transformIncludes(SubCart.includes, ...args);
    }

    public static classIncludes(): any {
        return SubCart.includes;
    }
}
