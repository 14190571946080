import { RequestAbstract } from 'common/helpers/resources/request.abstract';
import { environment } from 'environments/environment';

export class Resource extends RequestAbstract {
    protected baseUrl: string = `${environment.apiUrl}`;
    protected endpoint: string;
    private _url: string = null;

    public constructor(endpoint: string = ""){
        super();
        this.setEndpoint(endpoint);
    }

    private updateUrl(){
        this._url = this.baseUrl+this.endpoint;
    }

    /**
     * Get the complete url format.
     * @param extra Extra endpoint to add
     * @return string (baseUrl+endpoint+extra(Param))
     */
    protected url(extra: string = ""): string {
        return this._url+extra;
    }

    /**
     * Set and get the base url.
     * @param url For baseUrl
     * @return string
     */
    public setBaseUrl(url: string): string {
        this.baseUrl = url;
        this.updateUrl();
        return this.baseUrl;
    }

    /**
     * Set and get the endpoint.
     * @param url For endpoint
     * @return string
     */
    public setEndpoint(url: string): string {
        this.endpoint = url;
        this.updateUrl();
        return this.endpoint;
    }

    /**
     * Get the base url.
     * @return string
     */
    public getBaseUrl(): string {
        return this.baseUrl;
    }

        /**
     * Get the endpoint.
     * @return string
     */
    public getEndPoint(): string {
        return this.endpoint;
    }
}