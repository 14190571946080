import { HttpClient, HttpParams } from '@angular/common/http';

export class RequestAbstract {
    public static TYPE_GET = "get";
    public static TYPE_POST = "post";
    public static TYPE_DELETE = "delete";
    public static TYPE_PATCH = "patch";
    public static TYPE_PUT = "put";

    protected http: HttpClient;
    protected availableParams: Array<string> = [];
    protected availableUrlParams: Array<string> = [];

    /** Get
     * 
     * @param url string
     * @param params any (optional)
     * @param success any (optional)
     * @param error any (optional)
     * @return subscribe
     */
	public Get(url: string, params?: any, success?: any, error?: any){
		return this._get(this.createUrl(url, params), this.getParams(params), success, error);
	}

    /** Post
     * 
     * @param url string
     * @param data any
     * @param params any (optional)
     * @param success any (optional)
     * @param error any (optional)
     * @return subscribe
     */
	public Post(url: string, data: any, params?: any, success?: any, error?: any) {
		return this._post(this.createUrl(url, params), data, this.getParams(params), success, error);
    }

    /** Delete
     * 
     * @param url string
     * @param params any (optional)
     * @param success any (optional)
     * @param error any (optional)
     * @return subscribe
     */
    public Delete(url: string, params?: any, success?: any, error?: any){
		return this._delete(this.createUrl(url, params), this.getParams(params), success, error);
    }

    /** Patch
     * 
     * @param url string
     * @param data any
     * @param params any (optional)
     * @param success any (optional)
     * @param error any (optional)
     * @return subscribe
     */
    public Patch(url: string, data: any, params?: any, success?: any, error?: any){
		return this._patch(this.createUrl(url, params), data, this.getParams(params), success, error);
    }

    /** Put
     * 
     * @param url string
     * @param data any
     * @param params any (optional)
     * @param success any (optional)
     * @param error any (optional)
     * @return subscribe
     */
    public Put(url: string, data: any, params?: any, success?: any, error?: any){
		return this._put(this.createUrl(url, params), data, this.getParams(params), success, error);
    }

    /** createUrl
     * 
     * @param url string
     * @param params any
     * @return string
     */
    protected createUrl(url: string, params: any): string {
        if(params){
            this.availableUrlParams.forEach(function(param){
                url = url.replace(':'+param, params[param]);
            });
        }
        return url;
    }

    /** getParams
     * 
     * @param params any (optional)
     * @return HttpParams
     */
    protected getParams(params?: any): HttpParams {
        var newParams = new HttpParams();
        if(params){
            this.availableParams.forEach(function(param){
                if(params[param]!==undefined){
                    newParams = newParams.append(param, params[param]);
                }
            });
        }
        return newParams;
    }

    protected callWithoutBody(type: string, url: string, params: any, _success: any, _error: any){
        return this.http[type]<any>(url, {responseType: "json", params})
        .subscribe((response: any) => {
            this._callSuccess(response, _success);
        }, (error: any) => {
            this._callError(error, _error);
        });
    }

    protected callWithBody(type: string, url: string, body: any, params: any, _success: any, _error: any){
        return this.http[type]<any>(url, body, {responseType: "json", params})
        .subscribe((response: any) => {
            this._callSuccess(response, _success);
        }, (error: any) => {
            this._callError(error, _error);
        });
    }

    /** _get
     * 
     * @param url string
     * @param params any
     * @param success any
     * @param error any
     * @return callWithoutBody method
     */
    private _get(url: string, params: any, success: any, error: any){
        return this.callWithoutBody(RequestAbstract.TYPE_GET, url, params, success, error);
    }

    /** _post
     * 
     * @param url string
     * @param data any
     * @param params any
     * @param success any
     * @param error any
     * @return callWithBody method
     */
    private _post(url: string, data: any, params: any, success: any, error: any){
        return this.callWithBody(RequestAbstract.TYPE_POST, url, data, params, success, error);
    }

    /** _delete
     * 
     * @param url string
     * @param params any
     * @param success any
     * @param error any
     * @return callWithoutBody method
     */
    private _delete(url: string, params: any, success: any, error: any){
        return this.callWithoutBody(RequestAbstract.TYPE_DELETE, url, params, success, error);
    }

    /** _patch
     * 
     * @param url string
     * @param data any
     * @param params any
     * @param success any
     * @param error any
     * @return callWithBody method
     */
    private _patch(url: string, data: any, params: any, success: any, error: any){
        return this.callWithBody(RequestAbstract.TYPE_PATCH, url, data, params, success, error);
    }

    /** _put
     * 
     * @param url string
     * @param data any
     * @param params any
     * @param success any
     * @param error any
     * @return callWithBody method
     */
    private _put(url: string, data: any, params: any, success: any, error: any){
        return this.callWithBody(RequestAbstract.TYPE_PUT, url, data, params, success, error);
    }

    /**
     * _callSuccess - Calls _callMethod
     * @param data any
     * @param success any
     */
    private _callSuccess(data: any, success: any): void {
        this._callMethod(data, success);
    }

    /**
     * _callError - Calls _callMethod
     * @param data any
     * @param error any
     */
    private _callError(data: any, error: any): void {
        this._callMethod(data, error);
    }

    /**
     * _callMethod - Call a method if exists and pass the data
     * @param data any
     * @param method any
     */
    private _callMethod(data: any, method: any): void {
        if(method){
            method(data);
        }
    }
}