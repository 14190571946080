import { Model } from 'common/helpers/models/model.abstract';

export class Address extends Model {
    id : number;
    name: string;
    phone: string;
    street: string;
    outdoor_number:  any;
    interior_number:  any;
    colony: string;
    zip:any;
    city:string;
    state: string;
    country: string = "Mexico";

       /**
     * Constructor
     *
     * @param publication
     */
    public constructor(address?: any) {
        super();
        address = address || {};
        this.id = address.id || null;
        this.name = address.name || null;
        this.phone = address.phone || null;
        this.street = address.street || null;
        this.outdoor_number = address.outdoor_number || null;
        this.interior_number = address.interior_number || null;
        this.colony  = address.colony || null;
        this.zip  = address.zip || null;
        this.city = address.city || null;
        this.state  = address.state || null;
        this.country  = address.country || "Mexico";
    }
}

