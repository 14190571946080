import { Component, Inject } from '@angular/core';
import { appConfig } from 'common/app-config';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SplashScreenService } from 'common/services/splash-screen.service';

@Component({
    selector: 'modal-errorPayment',
    templateUrl: 'error-payment.component.html',
    styleUrls: ['error-payment.component.scss'],
})

export class ModalErrorPayment {
    appConfig: any;
    loading: boolean;
    server_error: string = '';
    public constructor(
        public _dialogRef: MatDialogRef<ModalErrorPayment>,
        public dialog: MatDialog,
        private splashScreenService: SplashScreenService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.appConfig = appConfig;
    }

    /**
     * On init
     */
    ngOnInit(): void {
    }


    public closeModal(data?: any){
        this._dialogRef.close(data);
    }
}
