import { Model } from 'common/helpers/models/model.abstract';

export class Photo extends Model {
    id: string;
    path: string;
    path_big: string;

    /**
     * Constructor
     *
     * @param category
     */
    public constructor(photo?: any) {
        super();
        photo = photo || {};
        this.id = photo.id || null;
        this.path = photo.path || '';
        this.path_big = photo.path_big || '';
    }
}
