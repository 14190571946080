import { Model } from 'common/helpers/models/model.abstract';
import { User } from 'common/models/user.model';

export class Auth extends Model {
    token?: string;

    protected static includes: any = {
        'user': User
    };

    public constructor(auth?: any) {
        super();
        auth = auth || {};
        this.token = auth.token || null;
    }

    public static getIncludes(...args: any): string {
        return Auth.transformIncludes(Auth.includes, ...args);
    }

    public static classIncludes(): any {
        return Auth.includes;
    }
}
