import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MobileMenuComponent } from './mobile-menu.component';

@NgModule({
    declarations: [
        MobileMenuComponent
    ],
    imports     :
		[
			CommonModule,
			RouterModule,
			TranslateModule,
    ],
    exports     : [
        MobileMenuComponent
    ]
})
export class MobileMenuModule
{
}
