import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';
import { Cart } from 'common/models/cart.model';

@Injectable({ providedIn: 'root' })
export class CartResource extends Resource {
    protected availableParams: Array<string> = [
        'include'
    ];
	public cart: any;

    public constructor(protected http: HttpClient) {
        super();
    }

	public Update(products: any, _success?: any, _error?: any){
		return this.Post(this.url()+'/carts', {cart: products}, {include: Cart.getIncludes()}, _success, _error);
	}

	public AnonymousUpdate(products: any, _success?: any, _error?: any){
		return this.Post(this.url()+'/carts/anonymous', {cart: products}, {include: Cart.getIncludes()}, _success, _error);
	}

	public GetAll(_success?: any, _error?: any){
		return this.Get(this.url()+'/carts', {include: Cart.getIncludes()}, _success, _error);
	}

    public verifyStoreCart(data: any, _success?: any, _error?: any){
    	return this.Post(this.url()+'/store/verify-store-cart', {"data":data}, null, _success, _error);
        // return new Promise((resolve, reject) => {
        //     this.http.post(this.apiProvider.getApiConfig()+'/store/verify-store-cart',{"data":data}).subscribe(response => {
        //         resolve(response);
        //     }, err => {
        //         reject(err);
        //     });
        // });
    } 

     // return this.Post(this.url()+'/store/verify-store-cart', ,{"data":data}, null, _success, _error);
}
