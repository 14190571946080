import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'common/services';
import { appConfig } from 'common/app-config';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ModalForgotPassword } from 'common/ui/modals/forgot-password/forgot-password.component';

@Component({
    selector: 'modal-login',
    templateUrl: 'login.html',
    styleUrls: ['login.scss'],
})

export class ModalLogin {
    loginForm: FormGroup;
    registerForm: FormGroup;
    appConfig: any;
    loading: boolean;
    server_error_login: string = '';
    server_error_register: string = '';

    public constructor(
        private _formBuilder: FormBuilder,
        public _dialogRef: MatDialogRef<ModalLogin>,
        private authService: AuthenticationService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.appConfig = appConfig;
    }

    /**
 * On init
 */
    ngOnInit(): void {
        this.loginForm = this.createLoginForm();
        this.registerForm = this.createRegisterForm();
    }

    /**
     * Create login form
     *
     * @returns {FormGroup}
    */
    private createLoginForm(): FormGroup {
        return this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(appConfig.passwordMinLength)]],
        });
    }

    /**
     * Create register form
     *
     * @returns {FormGroup}
    */
    private createRegisterForm(): FormGroup {
        return this._formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(appConfig.passwordMinLength)]]
        });
    }

    private successLogin(): void {
        this._dialogRef.close();
    }

    private errorLogin(error: any): void {
        this.server_error_login = error;
        this.loading = false;
    }

    public onClickLogin(formData: any) {
        console.log("this.form", this.loginForm);
        this.loading = true;
        this.server_error_login = '';
        this.authService.login(formData.email, formData.password, () => {
            return new Promise((resolve, reject) => {
            this.authService.authCheck((response: any) => {
                    // this.cartService.initCart();
                    this.successLogin();
                    resolve(response);
                }, (error: any) => {
                    console.log("error en el authCheck: ", error);
                    // this.cartService.initCart();
                    resolve(true);
                });
            });
            // this.successLogin();
        }, (error: any) => {
            this.errorLogin(error);
        });
    }

    private successRegister(): void {
        this._dialogRef.close();
    }

    private errorRegister(error: any): void {
        this.server_error_register = error;
        this.loading = false;
    }

    public onClickRegister(formData: any): void {
        this.loading = true;
        this.server_error_register = '';
        this.authService.signin({name:formData.name, email:formData.email, password:formData.password}, (response: any) => {
            this.successRegister();
        }, (error: any) => {
            this.errorRegister(error);
        });
    }

    openForgotPassword(): void {
        this._dialogRef.close({goToForgotPassword: true});
    }
}
