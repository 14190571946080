import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Resource } from 'common/helpers/resources/resource.abstract';

@Injectable({ providedIn: 'root' })
export class ProductResource extends Resource {
    protected availableParams: Array<string> = [
        'include',
        'id',
        'search',
        'searchJoin'
    ];
    protected searchableFields: Array<string> = ['name', 'description'];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(protected http: HttpClient) {
        super("/products");
    }

    /**
     * Get product
     *
     * @return {any}
     */
    public GetProduct(id: string, include?: any, _success?: any, _error?: any): any {
        console.log(id);
        return this.Get(this.url('/:id'), { 'id': id, include: include }, _success, _error);
        // return new Promise((resolve, reject) => {
        //     this._httpClient.get(this.baseUrl, {responseType:"json", params})
        //         .subscribe((response: any) => {
        //           resolve(response);
        //         }, error  => {
        // 			console.log("Error", error);
        // 		});
        // });
    }

    // private searchConstructor(value: any, category_id?: any) {
    //     var search: string = "";
    //     this.searchableFields.forEach((item) => {
    //         search += item + ":" + value + ";";
    //     });
    //     search = search.slice(0, -1);
    //     return search;
    // }

    public Search(value: any, category_id?: any, include?: any, _success?: any, _error?: any): any {
        // value = this.searchConstructor(value, category_id);
        var params = { search: value };
        return this.Post(this.url('-search'), params, include, _success, _error);
    }


    public FilterByIds(ids: any, include?: any, _success?: any, _error?: any): any {
        return this.Get(this.url(), {include: include, search: 'id:'+ ids}, _success, _error);
    }

    /**
     * Get product
     *
     * @return {any}
     */
    public GetProducts(_success?: any, _error?: any, include?: any): any {
        return this.Get(this.url(), { include: include }, _success, _error);
        // return new Promise((resolve, reject) => {
        //     this._httpClient.get(this.baseUrl, {responseType:"json", params})
        //     //this._httpClient.get(this.apiUrl +'/cms/stores/1/product/get-all', {responseType:"json", params})
        //         .subscribe((response: any) => {
        //             console.log("success en la request de getProducts", response);
        //             resolve(response);
        //         }, error  => {
        //             console.log("Error", error);
        //         });
        // });
    }

}
