import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FooterModule } from './component/footer/footer.module';
import { MenuModule } from './component/menu/menu.module';
// import { SidenavModule } from './component/sidenav/sidenav.module';
import { MobileMenuModule } from './component/mobile-menu/mobile-menu.module';
import { MatSidenavModule } from '@angular/material/sidenav';

import { LayoutComponent } from './layout.component';


@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        FooterModule,
        MatSidenavModule,
        MenuModule,
        // SidenavModule,
        MobileMenuModule,
        RouterModule
    ],
    exports: [
        LayoutComponent
    ]
})
export class LayoutModule
{
}
