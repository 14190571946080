import { Injectable } from '@angular/core';
import { StoreService } from 'common/services/store.service';
import { AuthenticationService } from 'common/services/authentication.service';
import { CartService } from 'common/services/cart.service';
import { CartResource } from 'common/resources/cart.resource';

@Injectable({providedIn: 'root'})
export class AppService {
	store_data : any;
	public price_max_credit_card : number = 10000;
	//WAU SANDBOX
	//public conekta_public_key : string = 'key_AfszPBEbyzuzURdbRY9JKdw';
	// LEMON SANDBOX
	public conekta_public_key : string = 'key_N52mEKWstjvQpAtgVpT7Xqw';

	public constructor(
        private storeService: StoreService,
        private authService: AuthenticationService,
        private cartService: CartService,
        private cartResource: CartResource
	) {}

	init() {
		//this.authService.authCheck();
		return new Promise((resolve, reject) => {
            Promise.all([
                this.getStore(),
                this.getUser(),
                this.verifyStoreCart()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
	}

	/**
     * Get store
     *
     * @returns {Promise<any>}
     */
    public getStore(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storeService.loadStore((response: any) => {
                console.log(response.data);
                 localStorage.setItem('oi-store', JSON.stringify(response.data));
                resolve(response);
            }, (error: any) => {
                //reject(error);
                resolve(true);
            });
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.authService.authCheck((response: any) => {
                this.cartService.initCart();
				resolve(response);
			}, (error: any) => {
                console.log("error en el authCheck: ", error);
                this.cartService.initCart();
				resolve(true);
			});
        });
    }

	/*setStoreData(data) {
		this.store_data = data;
	}

	getStoreData(){
		return this.store_data;
	}*/

	getPriceMaxCreditCard(){
		// console.log("Give me the fixed max price please ... : " + this.price_max_credit_card);
		return this.price_max_credit_card;
	}


    verifyStoreCart()
    {
        return new Promise((resolve, reject) => {
            let storeCart = JSON.parse(localStorage.getItem('currentCart'))
            if (storeCart) {
                this.cartResource.verifyStoreCart(storeCart, (response: any) => {
                     localStorage.setItem('currentCart', JSON.stringify(response));
                    resolve(response);
                }, (error: any) => {
                    localStorage.setItem('currentCart', null);
                    resolve();
                });
            }
            else
            {
                localStorage.setItem('currentCart', null);
                resolve();
            }
        });
    }

}
