import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Resource } from 'common/helpers/resources/resource.abstract';

@Injectable({ providedIn: 'root' })
export class UserResource extends Resource {
    protected ENDPOINT_CONEKTA_CARD: string = "/card";
    protected availableParams: Array<string> = ["include"];
    protected availableUrlParams: Array<string> = [
        'id'
    ];
	protected user: any;
    protected onUserChanged: BehaviorSubject<any>;

    public constructor(protected http: HttpClient) {
        super("/users");
    	this.onUserChanged = new BehaviorSubject({});
    }

	public Update(id: any, data: any, _success?: any, _error?: any): any {
        return this.Post(this.url("/:id"), data, {id: id}, _success, _error);
	}

	public UpdatePassword(data: any){
        return this.Post(this.url(), data);
		// return this.http.patch(`${environment.apiUrl}/users` , data );
	}

    public GetConektaCard(include?: any, success?: any, error?: any){
        return this.Get(this.url(this.ENDPOINT_CONEKTA_CARD), {include: include}, success, error);
    }

	/**
     * Edit user
     *
     * @param user
     * @returns {any}
    */
    public EditUser(user: any, _success?: any, _error?: any, include?: any): any {
        return this.Post(this.url(), user, {include: include}, _success, _error);
        // return new Promise((resolve, reject) => {
        //     this.http.post(this.baseUrl, user, {responseType:"json", params})
        //         .subscribe((response: any) => {
        //             resolve(response);
        //         }, error  => {
        //             reject(error);
        //             console.log("Error", error);
        //         });
        // });
    }
}
