import { Model } from 'common/helpers/models/model.abstract';

export class ModelMedia extends Model {
    id: number;
    avatar_id: number;
	phone:string;
    phone_country_code: string;
	whatsapp:string;
	whatsapp_country_code : string;
	email:string;
	instagram:string;
	facebook:string;
	twitter:string;

    /**
     * Constructor
     *
     * @param modelMedia
     */
    public constructor(modelMedia?: any) {
        super();
        modelMedia = modelMedia || {};
        this.id = modelMedia.id || null;
        this.avatar_id = modelMedia.phone || null;
        this.phone = modelMedia.avatar_id || null;
        this.phone_country_code = modelMedia.phone_country_code || null;
        this.whatsapp = modelMedia.whatsapp || null;
        this.whatsapp_country_code = modelMedia.whatsapp_country_code || null;
        this.email = modelMedia.email || null;
        this.instagram = modelMedia.instagram || null;
        this.facebook = modelMedia.facebook || null;
        this.twitter = modelMedia.twitter || null;
    }
}
