export const locale = {
    lang: 'es',
    data: {
      	'MENU': {
			'LINKS' : 'Enlaces',
			'HOME' : 'Inicio',
			'CATEGORIES' : 'Categorías',
			'ALL_CATEGORIES' : 'Todas las categorías',
			'ABOUT_US' : 'Nosotros',
			'CONTACT' : 'Contacto',
			'PROFILE' : 'Mi cuenta',
			'CLOSE_SESSION' : 'Cerrar sessión',
			'LOGIN' : 'Iniciar sesión',
			'FIND' : 'Buscar ...',
			'REMOVE_PRODUCT' : 'Quitar producto',
			'PRODUCTS' : 'Productos',
			'SHIPPING' : 'Envio',
			'TOTAL_DISCOUNT' : 'Total descuento',
			'TOTAL_PRODUCTS' : 'Total productos',
			'TOTAL_PRODUCTS_DISCOUNT' : 'SUBTOTAL',
			'TOTAL_CART' : 'Total pedido',
			'GO_TO_CART' : 'Ver el carrito',
			'CHECKOUT' : 'Ir a pagar',
			'EMPTY_CART' : 'Tu carrito esta vacío',
            'BUY_NOW' : 'Comprar ahora',
            'EMPTY_SEARCH': 'No se encontraron productos'
      	}
    }
};
