import { Model } from 'common/helpers/models/model.abstract';
import { Category } from 'common/models/category.model';

export class Local extends Model {
    
    id: number;
    name: string;
    type: string;
    photo: string;
    photo_cover: string = 'assets/images/defaultslide.png';
    discount:any =[];
    categories:any = [];

       /**
     * Constructor
     *
     * @param publication
     */
    public constructor(local?: any) {
        super();
        local = local || {};
        this.id = local.id || null;
        this.name = local.name || null;
        this.type = local.type || null;
        this.photo = local.photo || null;
        // this.local_discount_limit = local.local_discount_limit || null;
        this.discount = local.discount || [];
        this.photo_cover = local.photo_cover || 'assets/images/defaultslide.png';
        this.categories = local.categories || [];
    }


    protected static includes: any = {
        // 'wholesales': null,
        'photo': null,
        // 'gallery': null,
        // 'brand': Brand,
        'categories': Category,
        // 'productDiscount': null,
        // 'productLabels': null,
        // 'relatedProducts.photo':null,
        // 'relatedProducts.productDiscount.photo':null,
        // 'breadcrumb': null
    }

    public static getDefaultIncludes(){
        return this.getIncludes();
    }

    public static getIncludes(...args: any): string {
        return Local.transformIncludes(Local.includes, ...args);
    }

    public static classIncludes(): any {
        return Local.includes;
    }
}
