export const locale = {
    lang: 'es',
    data: {
      	'MMENU': {
					'LINKS' : 'Enlaces',
					'HOME' : 'Inicio',
					'CATEGORIES' : 'Categorías',
					'ALL_CATEGORIES' : 'Todas las categorías',
					'ABOUT_US' : 'Acerca de nosotros',
					'CONTACT' : 'Contacto',
					'PROFILE' : 'Mi cuenta',
					'CLOSE_SESSION' : 'Cerrar sessión',
					'LOGIN' : 'Iniciar sessión',
					'FIND' : 'Buscar ...',
					'REMOVE_PRODUCT' : 'Quitar producto',
					'PRODUCTS' : 'Productos',
					'SHIPPING' : 'Envio',
					'TOTAL' : 'Total',
					'GO_TO_CART' : 'Ver el carrito',
					'CHECKOUT' : 'Ir a pagar',
					'EMPTY_CART' : 'Tu carrito esta vacío',
					'BUY_NOW' : 'Comprar ahora',

      	}
    }
};
