import { Model } from 'common/helpers/models/model.abstract';

export class OrderPromotion extends Model {
    id: number;
    discount: number;
    store_id: number;
    label: string;
    code: string; 
    type: string;
    description: string;


    /**
     * Constructor
     *
     * @param product
     */
    public constructor(orderPromotion?: any) {
        super();
        orderPromotion = orderPromotion || {};
        this.id = orderPromotion.id || null;
        this.discount = orderPromotion.discount || 0;
        this.label = orderPromotion.label || '';
        this.code = orderPromotion.code || '';
        this.type = orderPromotion.type || null;
        this.description = orderPromotion.description || null;
    }
}
