import { Model } from 'common/helpers/models/model.abstract';

export class BrandDiscount extends Model {
    id: string;
    brand_id: number;
    discount: number;

    /**
     * Constructor
     *
     * @param brandDisount
     */
    public constructor(brandDiscount?: any) {
        super();
        brandDiscount = brandDiscount || {};
        this.id = brandDiscount.id || null;
        this.brand_id = brandDiscount.brand_id || null;
        this.discount = brandDiscount.discount || null;
    }
}

