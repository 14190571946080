export abstract class Model {
    protected static includes: any = {};

    public constructor(){

    }

    public static classIncludes(): any {
        return Model.includes;
    }

    public static getIncludes(...args: any): string {
        return Model.transformIncludes(Model.includes, ...args);
    }

    protected static transformIncludes(includes: any, withAll: boolean = false, attrs: Array<any> = []){
        return Model._getIncludes(includes, withAll, attrs);
    }

    private static _getIncludes(classIncludes: any, withAll: boolean, attrs: Array<any>): string {
        if(withAll){
            return Model.getAllIncludes(classIncludes, attrs);
        }
        return Model.getSimpleIncludes(classIncludes, attrs);
    }

    private static getSimpleIncludes(classIncludes: any, attrs: Array<any>): string {
        let includes = "";
        for(let include in classIncludes){
            if(includes){
                includes += ",";
            }
            includes += include;
        }
        return includes;
    }

    private static getAllIncludes(classIncludes: any, attrs: Array<any>): string {
        let includes = "";
        for(let include in classIncludes){
            if(includes){
                includes += ",";
            }
            if(classIncludes[include]){
                includes += Model._getSubincludes(include, classIncludes[include]);
            }else {
                includes += include;
            }
        }
        return includes;
    }

    private static _getSubincludes(include: string, c: any): string {
        let includes = "";
        for(let subInclude in c.classIncludes()){
            if(includes){
                includes += ",";
            }
            includes += include + "." + subInclude;
        }
        if(!includes){
            includes = include;
        }
        return includes;
    }
}