import { Model } from 'common/helpers/models/model.abstract';

export class Status extends Model {
    id: number;
    label: number;
    code: number;
    color: number;

    /**
     * Constructor
     *
     * @param product
     */
    public constructor(status?: any) {
        super();
        status = status || {};
        this.id = status.id || null;
        this.label = status.label || null;
        this.code = status.code || null;
        this.color = status.color || null;
    }
}
