import { Model } from 'common/helpers/models/model.abstract';
import { Category } from 'common/models/category.model';
import { Product } from 'common/models/product.model';
import { Local } from 'common/models/local.model';
// import { Wholesale } from 'common/models/wholesale.model';
import { Brand } from 'common/models/brand.model';
import { BrandDiscount } from 'common/models/brandDiscount.model';
import { ShippingPrice } from 'common/models/shippingPrice.model';
import { Photo } from 'common/models/photo.model';

export class Store extends Model {

	id:any;
	name:any;
	gallery: any;
	gallery_web: any;
	image_web:any;
	image_web_aboutus:any;
	phone:any;
	whatsapp:any;
	email:any;
	shippingPrice:any;
	conekta_key_public:any;
	discountproducts: any = [];
	locales : any = [];
	description:any;

	protected static includes: any = {
		'locales': null,
    };

	/**
	 * Constructor
	 *
	 * @param store
	 */
	public constructor(store?: any) {
        super();
		store = store || {};
		this.id = store.id || 1;
		this.name = store.name || '';
		this.whatsapp = store.whatsapp || null;
		this.conekta_key_public = store.conekta_key_public || null;
		this.gallery = store.gallery || null;
		this.gallery_web = store.gallery_web || null;
		this.image_web = store.image_web || null;
		this.image_web_aboutus = store.image_web_aboutus || null;
		this.email = store.email || null;
		this.description = store.description || null;
		this.phone = store.phone || null;
		this.shippingPrice = store.shippingprice || [];
		this.discountproducts = store.discountproducts || [];
		this.locales = store.locales || null;
	}

    public static getdefaultIncludes(){
        return Store.getIncludes(true);
    }

    public static getIncludes(...args: any): string {
        return Store.transformIncludes(Store.includes, ...args);
    }

    public static classIncludes(): any {
        return Store.includes;
    }
}
