import {Directive, Input, HostBinding} from '@angular/core';

@Directive({
    selector: 'img[default]',
    host: {
        '(error)':'updateUrl()',
        '(load)': 'load()',
        '[src]':'src'
    }
})
  
export class ImagePreloadDirective {
    @Input() src:string;
    @Input() default:string;
  
    updateUrl() {
        console.log("fallo al cargar la url");
        this.src = this.default;
    }

    /*
    * NOTE: this method trigger when url loads fine, 
    * You can use this class to show or hide loading-spinners/image-loading-overlays.
    */
    load(){
        //this.className = '';
    }
}