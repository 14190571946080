import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatDialogModule} from '@angular/material/dialog';
//import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as spanish } from './i18n/es';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'terms-and-conditions',
  templateUrl: 'terms-and-conditions.html',
  styleUrls: ['terms-and-conditions.scss'],
})
export class ModalTermsAndConditions {
    
    constructor(public dialogRef: MatDialogRef<ModalTermsAndConditions>) {}

    public closeModal(){
        this.dialogRef.close();
    }

}
