import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';

@Injectable({ providedIn: 'root' })

export class OrderResource extends Resource {
    protected ENDPOINT_ORDER: string = "/user/orders";
    protected ENDPOINT_GET_BY_ID: string = "/orders/:id";
    protected ENDPOINT_ORDER_CANCEL: string = "/orders/:id/cancel";
    protected ENDPOINT_PAYMENT: string = "/orders";
    protected availableParams: Array<string> = ["include"];
    protected availableUrlParams: Array<string> = ["id"];

    public constructor(protected http: HttpClient) {
        super();
    }

    public Create(data: any, include?: any, success?: any, error?: any){
        return this.Post(this.url(this.ENDPOINT_PAYMENT), data, {include: include}, success, error);
    }

    public GetAll(include?: any, success?: any, error?: any){
        return this.Get(this.url(this.ENDPOINT_ORDER), {include: include}, success, error);
    }

    public GetOrder(id: any, include?: any, success?: any, error?: any){
        return this.Get(this.url(this.ENDPOINT_GET_BY_ID), {id: id, include: include}, success, error);
    }

    public Cancel(id: any, success?: any, error?: any){
        return this.Get(this.url(this.ENDPOINT_ORDER_CANCEL), {id: id}, success, error);
    }

    public applyCart(data: any, success?: any, error?: any){
        return this.Post(this.url('/orders/apply-cart'), {"order":data}, null, success, error);
    }
}