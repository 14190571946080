// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    // production: false,
    // // fileUrl: 'http://localhost/papelopolis-back/public/uploads/',
    // // apiUrl: 'http://localhost/papelopolis-back/public',
    // apiUrl: 'https://api.papelopolis-dev.web-app-united.com',
    // fileUrl: 'https://api.papelopolis-dev.web-app-united.com/uploads/',
    // store_id: 1,
    // store_label: "papelopolis"

   production: false,
    hmr: false,
    apiUrl: 'https://apiv2.orienteimportcenter.com/v1',
    fileUrl: 'https://apiv2.orienteimportcenter.com/uploads/',
    store_id: 1,
    store_label: "orienteimportcenter"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
