import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Resource } from 'common/helpers/resources/resource.abstract';

@Injectable({ providedIn: 'root' })
export class StoreResource extends Resource {
    protected URL_BY_ID: string = `/${environment.store_id}`;
    protected STORE_LABEL: string = `${environment.store_label}`;
    protected URL_SEND_MAIL: string = "/send-mail";
    protected availableParams: Array<string> = ["include", "label"];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    public constructor(protected http: HttpClient) {
        super("/stores");
    }

    /**
     * get store
     *
     * @returns {any}
     */
    public GetStore(include?: any , _success?: any, _error?: any): any {
        return this.Get(this.url()+'/'+1, {include: include}, _success, _error);
        // return new Promise((resolve, reject) => {
        //     this._httpClient.get(this.baseUrl, {responseType:"json",params})
        //         .subscribe((response: any) => {
        //             resolve(response);
        //         }, error  => {
        //             console.log("Error getStores", error);
        //             reject(error);
        //         });
        // });
    }

    /**
     * Send mail
     *
     * @return {any}
     */
    public SendMail(data: any, _success?: any, _error?: any): any {
        return this.Post(this.url(this.URL_BY_ID + this.URL_SEND_MAIL), data, null, _success, _error);
        // return new Promise((resolve, reject) => {
        //     this._httpClient.post(this.baseUrl + '/send-mail', data)
        //         .subscribe((response: any) => {
        //             resolve(response);
        //         }, error  => {
        //             console.log("Error getStores", error);
        //             reject(error);
        //         });
        // });
    }
}
