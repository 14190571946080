import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Auth, User } from 'common/models';
import { UserResource } from 'common/resources';


@Injectable({ providedIn: 'root' })
export class UserService {
    public currentUser: BehaviorSubject<any>;

    public constructor(private userResource : UserResource){
        this.currentUser = new BehaviorSubject(new User);
    }

    init(user?: any){
        if(!user){
            user = new User(JSON.parse(localStorage.getItem('currentUser')) );
        }

        this.updateUserValue( (user) ? user : new User());
    }

    public get currentUserValue(): User {
        return this.currentUser.value;
    }

    private cleanUser(response: any): void {
        this.updateUserValue(new User());
        localStorage.removeItem('currentUser');
    }

    public saveUser(user: any): void {
        this.updateUserValue(user);
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    public updateUserValue(user: any): void {
        this.currentUser.next(user);
    }

    public editUser(user: any, success: any, error: any): void {
        this.userResource.EditUser(user, success, error, User.getIncludes());
    }
}
