import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';

@Injectable({ providedIn: 'root' })
export class LocalResource extends Resource {
    protected availableParams: Array<string> = [
        'include',
        'label',
        'search'
    ];
    protected availableUrlParams: Array<string> = [
        'id'
    ];
    protected include = 'photo,categories'; //TEMP: QUITAR INCLUDES DE RESOURCES, buscar en el modelo

    /**
     * Constructor
     *
     * @param {HttpClient} http
     */
    public constructor(protected http: HttpClient) {
        super("/locals");
    }

    /**
     * Get categories
     *
     * @return any
     */
    public GetLocal(id: any, include?: any, _success?: any, _error?: any): any {
        return this.Get(this.url('/:id'), {id: id, include: include}, _success, _error);
    }

    // /**
    //  * Get category
    //  *
    //  * @return any
    //  */
    // public GetCategories(_success?: any, _error?: any, include?: any): any {
    //     return this.Get(this.url(), {include: include}, _success, _error);
    //     // return new Promise((resolve, reject) => {
    //     //     //this.http.get(this.baseUrl, {responseType:"json",params})
    //     //     this.http.get(this.baseUrl, {responseType:"json",params})
    //     //         .subscribe((response: any) => {
    //     //             console.log("success en la request de getCategories", response);
    //     //             resolve(response);
    //     //         }, error  => {
    //     //             console.log("Error getCategories", error);
    //     //         });
    //     // });
    // }

    // /**
    //  * Get category
    //  *
    //  * @return any
    //  */
    // public GetSubcategories(label: string, _success?: any, _error?: any, include?: any): any {
    //     include = (include) ? include : this.include;
    //     return this.Get(this.url(), {label: label, include: include}, _success, _error);
    //     // return new Promise((resolve, reject) => {
    //     //     //this.http.get(this.baseUrl, {responseType:"json",params})
    //     //     this.http.get(this.baseUrl, {responseType:"json",params})
    //     //         .subscribe((response: any) => {
    //     //             console.log("success en la request de getSubCategories", response);
    //     //             resolve(response);
    //     //         }, error  => {
    //     //             console.log("Error getSubCategories", error);
    //     //         });
    //     // });
    // }

    // /**
    //  * Get product
    //  *
    //  * @return any
    //  */
    // public GetProducts(label: string, _success?: any, _error?: any, include?: any): any {
    //     include = (include) ? include : this.include;
    //     // var params = new  HttpParams().set('label', label).set('include', include).set('filter', 'parents');
    //     return this.Get(this.url(), {label: label, include: include}, _success, _error);
    //     // return new Promise((resolve, reject) => {
    //     //     //this.http.get(this.baseUrl, {responseType:"json",params})
    //     //     this.http.get(this.baseUrl, {responseType:"json",params})
    //     //         .subscribe((response: any) => {
    //     //             console.log("success en la request de getProducts", response);
    //     //             resolve(response);
    //     //         }, error  => {
    //     //             console.log("Error getSubCategories", error);
    //     //         });
    //     // });
    // }
}
