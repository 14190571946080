import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';


// @Injectable({ providedIn: 'root' })
// export class CartResource extends Resource {
//     protected availableParams: Array<string> = [
//         'include'
//     ];
// 	public cart: any;

//     public constructor(protected http: HttpClient) {
//         super("/carts");
//     }

// 	public Update(products: any, _success?: any, _error?: any){
// 		return this.Post(this.url(), {cart: products}, {include: Cart.getIncludes()}, _success, _error);
// 	}

// 	public AnonymousUpdate(products: any, _success?: any, _error?: any){
// 		return this.Post(this.url()+'/anonymous', {cart: products}, {include: Cart.getIncludes()}, _success, _error);
// 	}

// 	public GetAll(_success?: any, _error?: any){
// 		return this.Get(this.url(), {include: Cart.getIncludes()}, _success, _error);
// 	}
// }


@Injectable({ providedIn: 'root' })

export class AddressResource  extends Resource {

    protected availableParams: Array<string> = [
        'include'
    ];
	public cart: any;

    public constructor(protected http: HttpClient) {
        super("/addresses");
    }

    public Create(data: any, include?: any, success?: any, error?: any){
        return this.Post(this.url(), data, {include: include}, success, error);
    }

    // Create(data) {
    //     return new Promise((resolve, reject) => {
    //         this.http.post(this.apiProvider.getApiConfig()+'/addresses', data).subscribe(response => {
    //             resolve(response);
    //         }, err => {
    //             reject(err);
    //         });
    //     });
    // }

}