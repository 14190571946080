import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from 'environments/environment';
import { Resource } from 'common/helpers/resources/resource.abstract';

@Injectable({ providedIn: 'root' })
export class AuthenticationResource extends Resource {
    protected store_id: string = `${environment.store_id}`;
    // protected URL_SIGNING_UP: string = "/signing-up";
    // protected URL_FORGOT_PASS: string = "/forgot-password";
    // protected URL_LOGIN: string = "/clients/app/user/login";
    // protected URL_LOGOUT: string = "/logout";
    // protected URL_CONFIRM_MAIL: string = "/verify/:id";
    protected availableParams: Array<string> = ["include", "expires", "signature"];
    protected availableUrlParams: Array<string> = ["id"];

    /**
     * Constructor
     *
     * @param {HttpClient} http
     */
    public constructor(protected http: HttpClient) {
        super('');
    }

    public setDataAuth(user: any): any{
        return {
            id: (user.id) ? user.id: undefined,
            name: user.name,
            phone: user.phone,
            email: user.email,
            password: (user.password) ? user.password : undefined
        }
    }

    /**
     *  Signin
     *
     * @returns
     */
    public Signin(data: any, success?: any, error?: any){
        return this.Post(this.url('/register'), this.setDataAuth(data), null, success, error);
    }

    /**
     *  auth
     *
     * @return
     */
    public Auth(include?: any, success?: any, error?: any){
        console.log(include);
        console.log(success);
        console.log(error);
        return this.Get(this.url('/user/profile'), {include: 'orders,addresses,ordersverified'}, success, error);
    }

    /**
     *  confirm Email
     *
     * @returns
     */
    // public ConfirmEmail(data: any, success?: any, error?: any){
    //     return this.Get(this.url(this.URL_CONFIRM_MAIL), {id: data.user_id, expires: data.expires, signature: data.signature}, success, error);
    // }

    /**
     *  forgot Password
     *
     * @returns
     */
    public ForgotPassword(data: any, success?: any, error?: any){
        return this.Post(this.url('/password/forgot'), data, null, success, error);
    }

    /**
     *  login
     *
     * @returns
     */
    public Login(data: any, include?: any, success?: any, error?: any){
        return this.Post(this.url('/clients/app/user/login'), data, {include: include}, success, error);
    }

    /**
     *  login
     *
     * @returns
     */
    public Logout(success?: any, error?: any){
        return this.Get(this.url('/logout'), {id: null}, success, error);
    }
}
