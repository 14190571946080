import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ModalLogin } from 'common/ui/modals/login/login';
import { ModalTermsAndConditions } from 'common/ui/modals/terms-and-conditions/terms-and-conditions';
import { ModalForgotPassword } from 'common/ui/modals/forgot-password/forgot-password.component';
import { ModalErrorPayment } from 'common/ui/modals/error-payment/error-payment.component';

@NgModule({
    imports: [
      	MatDialogModule,
    	ReactiveFormsModule,
    	MatIconModule,
    	MatFormFieldModule,
    	MatInputModule,
    	CommonModule
    ],
    declarations: [
        ModalLogin,
        ModalTermsAndConditions,
        ModalForgotPassword,
        ModalErrorPayment
    ],
    exports: [
        ModalLogin,
        ModalTermsAndConditions,
        ModalForgotPassword,
        ModalErrorPayment
    ],
	entryComponents: [
	    ModalLogin,
        ModalTermsAndConditions,
        ModalForgotPassword,
        ModalErrorPayment
	],
})
export class ModalsModule { }