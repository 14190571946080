import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from 'common/services/translation-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Oriente Import Center';


  	/**
     * Constructor
     *
     * @param {TranslateService} _translateService
     * @param {TranslationLoaderService} translationLoaderService
     */
    constructor(
      private translationLoaderService: TranslationLoaderService,
      private _translateService: TranslateService
    ){
      //console.log("APP-COMPONENT CONSTRUCTOR");
      // Add languages
        // this._translateService.addLangs(['es', 'en']);
        this._translateService.addLangs(['es']);

        // Set the default language
        this._translateService.setDefaultLang('es');

        // Set the navigation translations
        //this.translationLoaderService.loadTranslations(navigationSpanish);

        // Use a language
        this._translateService.use('es');
    }

  	ngOnInit(): void{
      //console.log("APP COMPONENT ngOnInit");
    }

    ngAfterViewInit(): void{
      //console.log("APP COMPONENT ngAfterViewInit");
    }
}
