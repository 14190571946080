import { Component, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Category, Store, Product, Cart } from 'common/models';
import { ProductResource } from 'common/resources';
import { StoreService } from 'common/services';
import { CartService } from 'common/services';
import { AuthenticationService, UserService } from 'common/services';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalLogin } from 'common/ui/modals/login/login';
import { ModalForgotPassword } from 'common/ui/modals/forgot-password/forgot-password.component';
import { environment } from 'environments/environment';
import { Router } from "@angular/router";
import { TranslationLoaderService } from 'common/services/translation-loader.service';
import { locale as spanish } from './i18n/es';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { Subject , fromEvent  } from 'rxjs';

declare global {
    interface Window { porto: any; }
}

@Component({
    selector: 'e-commerce-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})


export class MenuComponent {
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    // locales: loc[];
    store: Store;
    user: any;
    isLogged: boolean;
    nbCartItems: number;
    cart: Cart;
    routeParams: any;
    fileUrl: string;
    search_value: string;
    // search_cat: number = 0;
    public onTypeSearch = false;
    public searchedProducts: Array<any> = [];
    protected searchCount = 0;
    private SEARCH_INCLUDES = "photo,productDiscount";
    private _unsubscribeAll: Subject<any>;

    public loaded: any = {
        search: true
    };

    public searchFocus: boolean = true;

    @Output() categoryActionOutput = new EventEmitter<any>();

    /**
     * Constructor
     */
    constructor(private storeService : StoreService,
		private cartService : CartService,
		public authService : AuthenticationService,
        public userService : UserService,
        private productResource: ProductResource,
		public route: ActivatedRoute,
		public dialog: MatDialog,
		private translationLoaderService: TranslationLoaderService,
		private router : Router,
	){
		this._unsubscribeAll = new Subject();
		this.translationLoaderService.loadTranslations(spanish);

		// this.categories = [new Category()];
        this.fileUrl =`${environment.fileUrl}`;
    }
    
    ngOnInit(): void{
        this.storeService.currentStore.subscribe(response => {
            this.store = <Store>(response);
            // this.categories = <Category[]>(response.categories);
        });

        this.authService.currentSession.subscribe(response => {
            this.isLogged = this.authService.isLogged();
        });
        
        this.cartService.currentCart.subscribe(response => {
            console.log('IN TH SUBSCRIBE');
            console.log(this.cart);
            this.cart = response;
            console.log('IN TH SUBSCRIBE');
            console.log(this.cart);
        });


        fromEvent(this.searchInput.nativeElement, 'keyup').pipe(

          // get value
          map((event: any) => {
            return event.target.value;
          })
          // if character length greater then 2
          , filter(res => res.length > 2)

          // Time in milliseconds between key events
          , debounceTime(1000)

          // If previous query is diffent from current   
          , distinctUntilChanged()

          // subscription for response
        ).subscribe((text: string) => {

            this.search(text)
         })
        



    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            window.porto.init();
        }, 500);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    hideSearchResults(){
        setTimeout(() => {
            this.searchFocus = false;
        }, 500);
    }

    goSearch(): void {
        //console.log("LETS GO AND FIND " + this.search_value + " within category " + this.search_cat);
        this.router.navigate(['/lista-de-productos', 'search', { search_value: this.search_value }]).then(
            (res: any) => {
                //console.log("FALLO LISTA DE PRODUCTOS, ROUTER.NAVIGATE");
            }, (error: any) => {
                //console.log('ERROR MenuComponent goSearch', error);
            }
        );
        this.onTypeSearch = false;
        this.searchedProducts = [];
    }

    private _successSearch(response: any){
        this.searchedProducts = response.data;
        this.loaded.search = true;
        this.onTypeSearch = true;
        console.log("_successSearch: ", this.searchedProducts);
    }

    private _errorSearch(error: any){
        this.loaded.search = true;
        this.searchedProducts = [];
    }

    private _search(value: any, idCategory?: number){
        this.loaded.search = false;
        // this.productResource.Search(value, idCategory, this.SEARCH_INCLUDES, (response: any) => {
        //     this._successSearch(response);
        // }, (error: any) => {
        //     this._errorSearch(error);
        // });
    }

    public search(value: any): void {
        // if(idCategory){
        //     idCategory = Number(idCategory);
        // }
        // this.searchCount++;
        // setTimeout(() => {
        //     this.searchCount--;
        //     if(this.searchCount==0){
        //         if(value){
                    this._search(value);
        //         }
        //     }
        // }, 300);
    }

    openModalLogin(): void {
        const dialogRef = this.dialog.open(ModalLogin);
        dialogRef.afterClosed().subscribe(result => {
            if(result && result.goToForgotPassword){
                this.openForgotPassword();
            }
        });
    }

    openForgotPassword(): void {
        const dialogRef = this.dialog.open(ModalForgotPassword, {panelClass: 'custom-container'});
        dialogRef.afterClosed().subscribe(result => {
            console.log("result", result);
            if(result && result.goToLogin){
                this.openModalLogin();
            }
        });
    }

    // toggleCategory() {
    //     //console.log("on toggleCategory");
    //     this.categoryActionOutput.emit(null);
    // }

    public logout(): void {
        this.authService.logout();
        this.router.navigate(['/']);
    }


    deleteProduct(suborderIndex, product){
        console.log(product);
        // this.cartService.removeFromCart($event)
        this.cartService.deleteProductLocalCart(product).then((cart:any) =>{
            console.log(cart);
            for (var i = this.cart.suborders[suborderIndex].products.length - 1; i >= 0; i--) {
                if ( this.cart.suborders[suborderIndex].products[i].id == product.id) {
                    this.cart.suborders[suborderIndex].products.splice(i, 1);
                    break;
                }
            }
            this.cart.total_without_discount = cart.total_without_discount;
            this.cart.wholesales_cart_discount = cart.wholesales_cart_discount;
            this.cart.total_local_discount = cart.total_local_discount;
            this.cart.shipping = cart.shipping;
            this.cart.total = cart.total;
            console.log(this.cart);
        });
    }
}
