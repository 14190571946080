import { Component, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
    selector   : 'e-commerce-layout',
    templateUrl: './layout.component.html',
    styleUrls  : ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent
{

    // @ViewChild('sidenav', {static: false}) sidenav;

    /**
     * Constructor
     */
    constructor()
    {
    }

    // sidenavToggle($event){
    //     //console.log("on sidenavToggle", $event);
    //     this.sidenav.toggle();
    // }
}
