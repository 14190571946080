import { Injectable } from '@angular/core';
import { Router, Route, UrlSegment, CanLoad 	} from '@angular/router';
import { Observable, throwError,of} from 'rxjs';
import { AuthenticationService } from 'common/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map,catchError } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class UserLoggedGuard implements CanLoad {
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private httpClient: HttpClient
	){ 

	}

	canLoad(route: Route, segments: UrlSegment[]) : Observable<boolean>|Promise<boolean>|boolean {
		console.log("GUARD - Check if we can load (only if logged)");
		if (!this.authenticationService.isLogged()){
			console.log("sin sesion activa no puede entrar");
			this.router.navigate(['/']);
		}

		return this.authenticationService.isLogged();
	}
}
