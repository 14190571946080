// import { FuseConfig } from '@fuse/types';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const appConfig: any = {
    // Color themes can be defined in src/app/app.theme.scss
    passwordMinLength:6,
	RFCPattern : /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
	zipPattern : /^\d{5}$/,
	countryCodePattern : /^[+]\d{2,4}$/,
	completeName : /^([A-zÀ-ÿ',\-]+\s[A-zÀ-ÿ',\-]+)+$/,
	phonePattern : /^\d{10}$/,
	mxStates : [
		{
			"name": "Cuidad de México",
			"code": "MX-CDM",
			"subdivision": "state"
		},
		{
			"name": "Aguascalientes",
			"code": "MX-AGU",
			"subdivision": "state"
		},
		{
			"name": "Baja California",
			"code": "MX-BCN",
			"subdivision": "state"
		},
		{
			"name": "Baja California Sur",
			"code": "MX-BCS",
			"subdivision": "state"
		},
		{
			"name": "Campeche",
			"code": "MX-CAM",
			"subdivision": "state"
		},
		{
			"name": "Chiapas",
			"code": "MX-CHP",
			"subdivision": "state"
		},
		{
			"name": "Chihuahua",
			"code": "MX-CHH",
			"subdivision": "state"
		},
		{
			"name": "Coahuila",
			"code": "MX-COA",
			"subdivision": "state"
		},
		{
			"name": "Colima",
			"code": "MX-COL",
			"subdivision": "state"
		},
		{
			"name": "Durango",
			"code": "MX-DUR",
			"subdivision": "state"
		},
		{
			"name": "Guanajuato",
			"code": "MX-GUA",
			"subdivision": "state"
		},
		{
			"name": "Guerrero",
			"code": "MX-GRO",
			"subdivision": "state"
		},
		{
			"name": "Hidalgo",
			"code": "MX-HID",
			"subdivision": "state"
		},
		{
			"name": "Jalisco",
			"code": "MX-JAL",
			"subdivision": "state"
		},
		{
			"name": "Michoacán",
			"code": "MX-MIC",
			"subdivision": "state"
		},
		{
			"name": "Morelos",
			"code": "MX-MOR",
			"subdivision": "state"
		},
		{
			"name": "México",
			"code": "MX-MEX",
			"subdivision": "state"
		},
		{
			"name": "Nayarit",
			"code": "MX-NAY",
			"subdivision": "state"
		},
		{
			"name": "Nuevo León",
			"code": "MX-NLE",
			"subdivision": "state"
		},
		{
			"name": "Oaxaca",
			"code": "MX-OAX",
			"subdivision": "state"
		},
		{
			"name": "Puebla",
			"code": "MX-PUE",
			"subdivision": "state"
		},
		{
			"name": "Querétaro",
			"code": "MX-QUE",
			"subdivision": "state"
		},
		{
			"name": "Quintana Roo",
			"code": "MX-ROO",
			"subdivision": "state"
		},
		{
			"name": "San Luis Potosí",
			"code": "MX-SLP",
			"subdivision": "state"
		},
		{
			"name": "Sinaloa",
			"code": "MX-SIN",
			"subdivision": "state"
		},
		{
			"name": "Sonora",
			"code": "MX-SON",
			"subdivision": "state"
		},
		{
			"name": "Tabasco",
			"code": "MX-TAB",
			"subdivision": "state"
		},
		{
			"name": "Tamaulipas",
			"code": "MX-TAM",
			"subdivision": "state"
		},
		{
			"name": "Tlaxcala",
			"code": "MX-TLA",
			"subdivision": "state"
		},
		{
			"name": "Veracruz",
			"code": "MX-VER",
			"subdivision": "state"
		},
		{
			"name": "Yucatán",
			"code": "MX-YUC",
			"subdivision": "state"
		},
		{
			"name": "Zacatecas",
			"code": "MX-ZAC",
			"subdivision": "state"
		}
	],
};
