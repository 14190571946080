import { Model } from 'common/helpers/models/model.abstract';

export class Role extends Model {
    public id: number;
    public code: string;
    public label: string;

    /**
     * Constructor
     *
     * @param role
     */
    public constructor(role?: any) {
        super();
        role = role || {};
        this.id = role.id || null;
        this.code = role.code || null;
        this.label = role.label || null;
    }
}
