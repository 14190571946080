import { Model } from 'common/helpers/models/model.abstract';

export class ShippingPrice extends Model {
	active: boolean;
	id: number;
	min_weight: number;
	price: number;
	store_id: number;

	/**
	 * Constructor
	 *
	 * @param store
	 */
	public constructor(shipPrice?: any) {
        super();
		shipPrice = shipPrice || {};
		this.active = shipPrice.active || true;
		this.id= shipPrice.is || null;
		this.min_weight= shipPrice.min_weight || 0;
		this.price= shipPrice.price || 0;
		this.store_id=shipPrice.store_id || null;
	}
}
