import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from 'common/models';
import { StoreResource } from 'common/resources';

@Injectable({ providedIn: 'root' })
export class StoreService {
    private currentStoreSubject: BehaviorSubject<Store>;
    public currentStore: Observable<Store>;
	private noStore : Store;

    public constructor(private storeResource: StoreResource) {
      	this.noStore = new Store();

	    this.currentStoreSubject = new BehaviorSubject<Store>(this.noStore);
	    this.currentStore = this.currentStoreSubject.asObservable();

		//this.loadStore();
    }

    public getCurrentStoreValue(): Store {
      return this.currentStoreSubject.value;
    }

    public loadStore(_success?: any, _error?: any){
        this.storeResource.GetStore(Store.getdefaultIncludes(), (response: any) => {
            this._successGetStore(response);
            if(_success){
                _success(response);
            }
        }, (error: any) => {
            this._errorGetStore(error);
            if(_error){
                _error(error);
            }
        });
	}

  	private _successGetStore(response: any): void{
        ////console.log("response _successGetStore in servie of store", response);
        this.currentStoreSubject.next(<Store>response.data);
    }

    private _errorGetStore(error: any): void{
        console.log("error en el resolve de product", error);
        this.currentStoreSubject.next(this.noStore);
    }
}
