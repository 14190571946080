import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
// import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MenuComponent } from './menu.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from 'common/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        MenuComponent
    ],
    imports     : [
			CommonModule,
			RouterModule,
			// ReactiveFormsModule,
			FormsModule,
			MatIconModule,
			MatFormFieldModule,
			MatDialogModule,
	    MatInputModule,
			DirectivesModule,
			TranslateModule,

    ],
    exports     : [
        MenuComponent
    ]
})
export class MenuModule
{
}
