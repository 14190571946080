import { Component } from '@angular/core';

import { Store, User } from 'common/models';

import { StoreService, AuthenticationService, UserService } from 'common/services';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ModalLogin } from 'common/ui/modals/login/login';
import { ModalTermsAndConditions } from 'common/ui/modals/terms-and-conditions/terms-and-conditions';
import { ModalForgotPassword } from 'common/ui/modals/forgot-password/forgot-password.component';

@Component({
    selector   : 'e-commerce-footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
	store: Store;
    user : User;
    isLogged : boolean;

    /**
     * Constructor
     */
    constructor(
        private storeService: StoreService,
        public authenticationService : AuthenticationService,
        public userService : UserService,
        public authService : AuthenticationService,
        public dialog: MatDialog
    ){
        this.store = new Store();
        this.storeService.currentStore.subscribe(response => {
            this.store = new Store(response);
        });

        this.authService.currentSession.subscribe(response => {
            this.isLogged = this.authService.isLogged();
        });
    }


    openModalLogin(): void {
        //document.querySelector('body').classList.remove('mmenu-active');

        const dialogRef = this.dialog.open(ModalLogin);


        dialogRef.afterClosed().subscribe(result => {
            console.log("result", result);
            if(result && result.goToForgotPassword){
                this.openForgotPassword();
            }
        });
    }
    openForgotPassword(): void {
        //document.querySelector('body').classList.remove('mmenu-active');

        const dialogRef = this.dialog.open(ModalForgotPassword, {panelClass: 'custom-container'});


        dialogRef.afterClosed().subscribe(result => {
            console.log("result", result);
            if(result && result.goToLogin){
                this.openModalLogin();
            }
        });
    }

    openTermsAndConditions(): void {
        const dialogRef = this.dialog.open(ModalTermsAndConditions, {panelClass: 'custom-container'});

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }



}
