import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from "@angular/common";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

import { LayoutModule } from './layout/layout.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor, HeadersInterceptor } from 'common/interceptors';

import { AppService } from 'common/services';
import { SplashScreenService } from 'common/services';

import { ReactiveFormsModule } from '@angular/forms';

import { ModalsModule } from 'common/ui/modals/modal.module';
import { UserLoggedGuard } from 'common/guards/userLogged.guard';

import { NgxUsefulSwiperModule  } from 'ngx-useful-swiper';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { RedZoomModule } from 'ngx-red-zoom';
// import { ValidateCartComponent } from './main/e-commerce/validate-cart/validate-cart.component';
// import { LocalComponent } from './main/catalog/local/local.component';

//import { AuthStoreGuard } from 'common/guards/authStore.guard';

const appRoutes: Routes = [
    {
        path      : 'inicio',
        loadChildren: () => import('./main/catalog/home/home.module').then(m => m.HomeModule),
        pathMatch: 'full'
    },
    {
        path      : 'producto',
        loadChildren: () => import('./main/catalog/product/product.module').then(m => m.ProductModule),
    },
    {
        path      : 'productos/:label',
        loadChildren: () => import('./main/catalog/product/product.module').then(m => m.ProductModule),
    },
    {
        path      : 'local/:id',
        loadChildren: () => import('./main/catalog/local/local.module').then(m => m.LocalModule),
    },
    {
        path      : 'categorias',
        loadChildren: () => import('./main/catalog/categories/categories.module').then(m => m.CategoriesModule),
    },
    {
        path      : 'lista-de-productos',
        loadChildren: () => import('./main/catalog/list-products/list-products.module').then(m => m.ListProductsModule),
    },
    {
        path      : 'nosotros',
        loadChildren: () => import('./main/catalog/about-us/about-us.module').then(m => m.AboutUsModule),
    },
    {
        path      : 'contactanos',
        loadChildren: () => import('./main/catalog/contact-us/contact-us.module').then(m => m.ContactUsModule),
    },
    {
        path      : 'checkout/:id_order',
        loadChildren: () => import('./main/e-commerce/checkout/checkout.module').then(m => m.CheckoutModule),
    },
    {
        path      : 'validate-cart',
        loadChildren: () => import('./main/e-commerce/validate-cart/validate-cart.module').then(m => m.ValidateCartModule),
    },
    {
        path      : 'pago-exitoso',
        loadChildren: () => import('./main/e-commerce/success-order/success-order.module').then(m => m.SuccessOrderModule),
    },
    {
        path      : 'perfil',
        loadChildren: () => import('./main/user/user.module').then(m => m.UserModule),
        canLoad: [UserLoggedGuard]
    },
    {
        path      : 'shopping-cart',
        loadChildren: () => import('./main/e-commerce/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
    },
    {
        path      : '',
        loadChildren: () => import('./main/catalog/home/home.module').then(m => m.HomeModule)
    },
    {
        path      : '**',
        redirectTo: 'inicio'
    }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { useHash: true, scrollPositionRestoration: 'enabled'}),
    NgxGoogleAnalyticsModule.forRoot('UA-173910821-1'),
    NgxGoogleAnalyticsRouterModule,
    TranslateModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    ModalsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxUsefulSwiperModule,
     NgxImageZoomModule.forRoot(),
     RedZoomModule,
  ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
      { provide: APP_INITIALIZER, useFactory: AppServiceFactory, multi: true, deps: [AppService, SplashScreenService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function AppServiceFactory(appService: AppService, SplashScreenService: SplashScreenService) {
  return () => appService.init();
}
