import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PaymentInformationService
{

    PaymentInfo: any = null;
    /**
     * Constructor
     *
     */
    constructor()
    {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getPayment() {
        return this.PaymentInfo;
    }

    setPayment(paymentInfo: any): void{
        this.PaymentInfo = paymentInfo;
    }

    reset() {
        this.PaymentInfo = null;
    }
    
}
