import { Model } from 'common/helpers/models/model.abstract';
import { BrandDiscount } from 'common/models/brandDiscount.model';

export class Brand extends Model {
    id: string;
    label: string;
    name: string;

    discount: BrandDiscount;

    public static includes: any = {
        'photo': null
    };

    /**
     * Constructor
     *
     * @param brand
     */
    public constructor(brand?: any) {
        super();
        brand = brand || {};
        this.id = brand.id || null;
        this.label = brand.label || null;
        this.name = brand.name || null;
    }

    public getIncludes(...args: any): string {
        return Brand.transformIncludes(Brand.includes, ...args);
    }

    public static classIncludes(): any {
        return Brand.includes;
    }
}

